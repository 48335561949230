import Vue from 'vue'
import permissionsMiddleware from './middleware/permissionsMiddleware'
import App from './App.vue'
import vuetify from "./plugins/vuetify"
import VueProgressBar from 'vue-progressbar'
import router from "./routes"
import store from './store/index'
import JsonExcel from "vue-json-excel";

import vuescroll from 'vuescroll';

Vue.use(vuescroll);

import './global'
import './directives'
import './filters'
import {func} from './functions'

Vue.prototype.$func = func
import VueMoment from "vue-moment"
import moment from 'moment-timezone'
import VueEasyLightbox from 'vue-easy-lightbox'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places',
    },
})
Vue.config.productionTip = false
Vue.use(VueEasyLightbox)
Vue.use(permissionsMiddleware)
Vue.component("downloadExcel", JsonExcel);
require('moment/locale/fr')
Vue.use(VueMoment, {
    moment
});
const options = {
    color: '#FF7D14',
    failedColor: '#FF2442',
    thickness: '3px',
}
Vue.use(VueProgressBar, options)
new Vue({
    render: h => h(App),
    store,
    router,
    vuetify,
}).$mount('#app')
